import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { ButtonComponent } from './components/button/button.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { AutoFocusDirective } from './directives/autofocus';
import { IgnoreEscapedValue } from './directives/ignoreEscapedValue';
import { FocusOut } from './directives/focusout';
import { ClickOutsideDirective } from './directives/clickOutside';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast.component';
import { SwitchComponent } from './components/switch/switch.component';
import { FavouriteStarComponent } from './components/favourite-star/favourite-star.component';
import { AdminUsersDropdownComponent } from './components/admin-users-dropdown/admin-users-dropdown.component';
import { ModalComponent } from './components/modal/modal.component';
import { MissingHoursAlertComponent } from './components/missing-hours-alert/missing-hours-alert.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ButtonComponent,
    DatePickerComponent,
    SkeletonComponent,
    AutoFocusDirective,
    IgnoreEscapedValue,
    FocusOut,
    ClickOutsideDirective,
    SpinnerComponent,
    ToastComponent,
    SwitchComponent,
    FavouriteStarComponent,
    AdminUsersDropdownComponent,
    ModalComponent,
    MissingHoursAlertComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [],
  exports: [
    HeaderComponent,
    ButtonComponent,
    DatePickerComponent,
    SkeletonComponent,
    AutoFocusDirective,
    IgnoreEscapedValue,
    FocusOut,
    ClickOutsideDirective,
    SpinnerComponent,
    ToastComponent,
    SwitchComponent,
    FavouriteStarComponent,
    ModalComponent,
    MissingHoursAlertComponent
  ],
})
export class CommonsModule {}
