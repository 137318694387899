import { Component, EventEmitter, Input, Output } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
@Component({
  selector: 'app-missing-hours-alert',
  template: `
    <div>
      @if (isLoading) {
        <div class="pt-7">
          <app-spinner
            class="text-primary"
            width="20px"
            height="20px"
            [animated]="true"
          ></app-spinner>
        </div>
      }
      @else if (hasMissingHours) {
        <div class="pt-7 text-strawberry flex justify-between items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-5 h-5 cursor-pointer"
            (click)="toggleTooltip()"
          >
            <path fill="currentColor" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
          </svg>
          @if (showTooltip) {
            <span
              class="bg-red-100 text-red-700 rounded-md border border-red-300 p-1 ml-2"
            >
              Mancano delle ore da segnare nella settimana del
              <span
                class="cursor-pointer underline underline-offset-2"
                (click)="onWeekClick()"
              >
                {{ formatDate(week) }}
              </span>
            </span>
          }
        </div>
      }
    </div>
  `,
})
export class MissingHoursAlertComponent {
  @Input() isLoading: boolean | null = false;
  @Input() hasMissingHours: boolean | null  = false;
  @Input() week: Date | null  = null;

  @Output() weekClicked = new EventEmitter<Date>();

  showTooltip = false;

  formatDate(date: Date | null) {
    if (!date) return null;
    return format(date, 'dd MMMM', { locale: it });
  }

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }

  onWeekClick() {
    if (this.week) {
      this.weekClicked.emit(this.week);
    }
  }
}
