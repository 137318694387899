import { inject, Injectable } from '@angular/core';
import { endOfWeek, format, parseISO, startOfDay, startOfWeek, subWeeks } from 'date-fns';
import { it } from 'date-fns/locale';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { Absence } from 'src/app/models/absence.interface';
import { TimeEntriesByTaskId } from 'src/app/models/time-entries-by-task-id.interface';
import { ClickupService } from 'src/app/services/clickup.service';
import { HOUR_IN_MS, WORKING_WEEKLY_HOURS_MS } from '../../../../../lambda/app/utils/milliseconds';

export type WeeklyMillisecondsByDay = Record<string, number>


@Injectable({
  providedIn: 'root',
})
export class MissingHoursCalculatorService {
  private clickupService = inject(ClickupService);
  public error$ = new Subject<string>();

  timeByThreeWeeks: WeeklyMillisecondsByDay = {};
  isLoadingMissingHours$ = new BehaviorSubject<boolean>(false);
  hasMissingHours$ = new BehaviorSubject<boolean>(false);
  week$ = new BehaviorSubject<Date | null>(null);


  constructor() {}

  getTimeEntriesByThreeWeeks() {
    const startDate = startOfDay(subWeeks(startOfWeek(new Date()), 3));
    const endDate = endOfWeek(subWeeks(new Date(), 1));
    this.isLoadingMissingHours$.next(true);

    forkJoin({
      timeEntries: this.clickupService.getTimeClickUp(startDate.getTime(), endDate.getTime(), { withCache: false }),
      absences: this.clickupService.getAbsences(startDate.getTime(), endDate.getTime(), { withCache: false }),
    }).subscribe({
      next: ({absences, timeEntries}) => {
        this.timeByThreeWeeks = this.calculateWeeklyTotals(timeEntries, absences);
        Object.entries(this.timeByThreeWeeks).forEach(([weekName, weekTotal]) => {
          if (weekTotal < WORKING_WEEKLY_HOURS_MS) {
            this.week$.next(new Date(weekName));
            this.hasMissingHours$.next(true);
          }
        });
        this.isLoadingMissingHours$.next(false);
      },
      error: (err) => {
        this.isLoadingMissingHours$.next(false),
        this.error$.next('Error calculating missing hours: ' + err.message);
      }
    })

  }


  calculateWeeklyTotals(timeEntries: TimeEntriesByTaskId[], absences: Absence[]): WeeklyMillisecondsByDay {
    const weeklyTotals: WeeklyMillisecondsByDay = {};

    timeEntries.forEach((taskTime: TimeEntriesByTaskId) => {
      Object.entries(taskTime.time_entries).forEach(([dateStr, milliseconds]) => {
        const weekName = startOfWeek(parseISO(dateStr), { weekStartsOn: 1 }).toString();
        weeklyTotals[weekName] = (weeklyTotals[weekName] || 0) + (milliseconds ?? 0);
      });
    });

    absences.forEach((absence: Absence) => {
      const startDate = new Date(absence.start_date);
      const endDate = new Date(absence.due_date);
      let absenceDuration = (endDate.getTime() - startDate.getTime());

      if(absenceDuration >= 8 * HOUR_IN_MS) {
        absenceDuration = Math.min(absenceDuration, 8 * HOUR_IN_MS);
      }
      const absenceWeekName = startOfWeek(parseISO(startDate.toString()), { weekStartsOn: 1 }).toString();
      weeklyTotals[absenceWeekName] = (weeklyTotals[absenceWeekName] || 0) + absenceDuration;
    });

    return weeklyTotals;
  }

}
