export const MINUTE_IN_MS = 60 * 1000;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;
export const WEEK_IN_MS = 7 * DAY_IN_MS;
export const MONTH_IN_MS = 30 * DAY_IN_MS;
export const WORKING_WEEKLY_HOURS_MS = 40 * HOUR_IN_MS;

export const START_WORKING_HOURS = 8;
export const END_WORKING_HOURS = 19;

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function clampToWorkingHours(time: number, currentDay: number) {
  return clamp(
    time,
    currentDay + HOUR_IN_MS * START_WORKING_HOURS,
    currentDay + HOUR_IN_MS * END_WORKING_HOURS,
  );
}

export function isIntersectingDay(
  start: number,
  end: number,
  startDay: number,
  endDay: number,
) {
  return (
    (end >= startDay && end <= endDay) ||
    (start >= startDay && start <= endDay) ||
    (start < startDay && end > endDay)
  );
}
